import { AuthContext } from '@/components/molecules/AuthProvider';
import { FullStory, init } from '@fullstory/browser';
import { createContext, PropsWithChildren, useContext, useEffect } from 'react';

interface FullStoryContextType {
  trackFullStoryEvent: (
    eventName: string,
    eventProperties: Record<string, unknown>
  ) => void;
}

export const FullStoryContext = createContext<FullStoryContextType | null>(
  null
);

export const FullStoryContextProvider = ({ children }: PropsWithChildren) => {
  const authContext = useContext(AuthContext);
  const user = authContext?.user;

  const trackFullStoryEvent = (
    eventName: string,
    eventProperties: Record<string, unknown>
  ) => {
    FullStory('trackEvent', {
      name: eventName,
      properties: eventProperties,
    });
  };

  useEffect(() => {
    init({
      orgId: process.env.NEXT_PUBLIC_FULL_STORY_ORG_ID as string,
    });
  }, []);

  useEffect(() => {
    if (user) {
      FullStory('setIdentity', {
        uid: user?.email,
        properties: {
          evId: user?.evId,
          email: user?.email,
          firstName: user?.firstName,
          lastName: user?.lastName,
          identityProvider: user?.identityProvider,
          phone: user?.phone,
        },
      });
    }
  }, [user]);

  return (
    <FullStoryContext.Provider
      value={{
        trackFullStoryEvent,
      }}
    >
      {children}
    </FullStoryContext.Provider>
  );
};

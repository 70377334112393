import {
  createContext,
  useState,
  useContext,
  PropsWithChildren,
  useEffect,
} from 'react';
import { useRouter } from 'next/router';

interface PreviousUrlContextType {
  previousUrl: string | null;
}

const PreviousUrlContext = createContext<PreviousUrlContextType | null>(null);

export const PreviousUrlProvider = ({ children }: PropsWithChildren) => {
  const router = useRouter();
  const [previousUrl, setPreviousUrl] = useState<string | null>(null);

  useEffect(() => {
    const handleRouteChange = () => {
      sessionStorage.setItem('previousUrl', window.location.href);
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setPreviousUrl(sessionStorage.getItem('previousUrl'));
    }
  }, []);

  return (
    <PreviousUrlContext.Provider value={{ previousUrl }}>
      {children}
    </PreviousUrlContext.Provider>
  );
};

export const usePreviousUrl = (): PreviousUrlContextType => {
  const context = useContext(PreviousUrlContext);
  if (!context) {
    throw new Error('usePreviousUrl must be used within a PreviousUrlProvider');
  }
  return context;
};
